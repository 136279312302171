.slider_container {
	display: none;
	width: 320px;
	height: 172px;
	margin: 0 auto;
}
.working_card {
	// background-size: 100%;
	height: 172px;
	object-fit: cover;
	border: 1px solid black;
	display: flex !important;
	justify-content: center;
	align-items: end;
	background-size: cover;
	opacity: 0.9;
}
.working_card_title {
	color: white;
	-webkit-text-stroke: 0.04em #934817;
	font-size: 20px;
	font-weight: 700;
	text-shadow: 0px 0px 6px black;
}
.arrow_img {
	width: 20px;
	margin: 26% auto;
}
.working_1 {
	background-image: url("../Images/Main/working_1.jpg");
}
.working_2 {
	background-image: url("../Images/Main/working_2.png");
}
.working_3 {
	background-image: url("../Images/Main/working_3.jpg");
}
.working_4 {
	background-image: url("../Images/Main/working_4.jpg");
}
.working_5 {
	background-image: url("../Images/Main/working_5.jpg");
}
.working_6 {
	background-image: url("../Images/Main/working_6.webp");
}
.working_7 {
	background-image: url("../Images/Main/working_7.jpg");
}
.working_8 {
	background-image: url("../Images/Main/working_8.jpg");
}
.working_9 {
	background-image: url("../Images/Main/working_9.jpg");
}
@media (max-width: 500px) {
	.slider_container {
		display: block;
	}
	.working_card_title {
		font-size: 16px;
		font-weight: 500;
		-webkit-text-stroke: 0;
		text-shadow: 1px 1px 1px #934817, -1px -1px 1px #934817,
			-1px 1px 1px #934817, 1px -1px 1px #934817;
	}
}

@import "node_modules/normalize-scss/sass/normalize";
.App {
	text-align: center;
}

.App-logo {
	height: 40vmin;
	pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
	.App-logo {
		animation: App-logo-spin infinite 20s linear;
	}
}

.App-header {
	background-color: #282c34;
	min-height: 100vh;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	font-size: calc(10px + 2vmin);
	color: white;
}

.App-link {
	color: #61dafb;
}

@keyframes App-logo-spin {
	from {
		transform: rotate(0deg);
	}
	to {
		transform: rotate(360deg);
	}
}
.slick-prev:before,
.slick-next:before {
	display: none;
}
.slick-prev:hover,
.slick-next:hover {
	border-color: #ff7e00;
	border-style: solid;
}
.active {
	color: #ff7e00 !important;
	cursor: default;
}
.container {
	margin: auto 140px;
}

.question {
	height: 302px;
	display: flex;
	flex-direction: column;
	align-items: center;
	background: url("../Images/Main/question.jpeg") no-repeat;
	background-position: 50% 58%;
	background-size: cover;
	background-color: rgba($color: #000000, $alpha: 0.3);
	padding: 30px;
	border: 1px solid #f37421;
}
.question_title {
	color: white;
	-webkit-text-stroke: 0.1rem rgba($color: #ff7e00, $alpha: 0.6);
}
.question_subtitle {
	color: white;
	-webkit-text-stroke: 0.05rem rgba($color: #ff7e00, $alpha: 0.6);
	font-weight: 700;
	font-size: 20px;
	width: 340px;
	margin: 0;
}
.question_btn {
	width: 260px;
	background-color: rgba($color: #ff7e00, $alpha: 0.8);
	border: 1px solid #f37421;
	box-shadow: 0 0 5px 1px #ffffff;
	border-radius: 25px;
	margin-top: 54px;
}
.question_btn:hover {
	background-color: rgba($color: #ff7e00, $alpha: 1);
	cursor: pointer;
}
.question_btn:active {
	background-color: white;
	box-shadow: inset 0 0 5px #f37421;
}
.question_btn_title {
	color: white;
	-webkit-text-stroke: 0.04em rgba($color: #000000, $alpha: 0.5);
	text-shadow: 0px 4px 4px #b67a3f;
	margin: 10px;
	font-size: 24px;
	font-weight: 600;
}
@media (max-width: 500px) {
	.question {
		height: 250px;
		padding: 0;
		background-position: 50% 67%;
		background-size: inherit;
	}
	.question_title {
		font-size: 22px;
		font-weight: 600;
		-webkit-text-stroke: 0;
		text-shadow: 1px 1px 1px rgba($color: #ff7e00, $alpha: 0.6),
			-1px -1px 1px rgba($color: #ff7e00, $alpha: 0.6),
			-1px 1px 1px rgba($color: #ff7e00, $alpha: 0.6),
			1px -1px 1px rgba($color: #ff7e00, $alpha: 0.6);
	}
	.question_subtitle {
		font-size: 18px;
		font-weight: 500;
		-webkit-text-stroke: 0;
		text-shadow: 1px 1px 1px #934817cc, -1px -1px 1px #934817cc,
			-1px 1px 1px #934817cc, 1px -1px 1px #934817cc;
	}
	.question_btn {
		margin-top: 38px;
	}
	.question_btn_title {
		font-size: 14px;
		font-weight: 500;
		-webkit-text-stroke: 0;
		text-shadow: 1px 1px 1px rgba($color: #000000, $alpha: 0.5),
			-1px -1px 1px rgba($color: #000000, $alpha: 0.5),
			-1px 1px 1px rgba($color: #000000, $alpha: 0.5),
			1px -1px 1px rgba($color: #000000, $alpha: 0.5), 0px 4px 4px #b67a3f;
	}
}

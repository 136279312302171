.form_container {
	display: flex;
	justify-content: center;
	gap: 40px;
}
.form {
	width: 840px;
	display: flex;
	flex-wrap: wrap;
	gap: 32px;
}
.form_box {
	position: relative;
}
.input {
	width: 250px;
	height: 40px;
}
.input::placeholder {
	font-size: 17px;
}
.textarea {
	width: 840px;
}
.textarea::placeholder {
	font-size: 20px;
	font-weight: 400;
}
.checkbox_title {
	width: 570px;
}
:focus::-webkit-input-placeholder {
	color: transparent;
}
:focus::-moz-placeholder {
	color: transparent;
}
:focus:-moz-placeholder {
	color: transparent;
}
:focus:-ms-input-placeholder {
	color: transparent;
}
.submit {
	width: 180px;
	height: 50px;
	margin-right: 40px;
	border: 1px solid #f37421;
	background-color: rgba($color: #ff7e00, $alpha: 0.6);
	border-radius: 50px;
	color: white;
	font-size: 22px;
	-webkit-text-stroke: 0.05rem rgb(255, 126, 0);
	font-weight: 600;
}
.submit:hover {
	background-color: rgba($color: #ff7e00, $alpha: 1);
	cursor: pointer;
}
.submit:active {
	background-color: white;
	box-shadow: inset 0 0 5px #f37421;
}
.submit_box {
	display: flex;
	align-items: center;
	width: 100%;
}

// -------------------------------------------------------------------------------------------------
input[type="checkbox"]:checked,
input[type="checkbox"]:not(:checked) {
	position: absolute;
	left: -9999px;
}

input[type="checkbox"]:checked + label,
input[type="checkbox"]:not(:checked) + label {
	display: inline-block;
	position: relative;
	padding-left: 28px;
	line-height: 20px;
	cursor: pointer;
}
input[type="checkbox"]:checked + label:before,
input[type="checkbox"]:not(:checked) + label:before {
	content: "";
	position: absolute;
	left: 0px;
	top: 0px;
	width: 18px;
	height: 18px;
	border: 1px solid #dddddd;
	background-color: #ffffff;
}

input[type="checkbox"]:checked + label:before,
input[type="checkbox"]:not(:checked) + label:before {
	border-radius: 2px;
}
input[type="checkbox"]:checked + label:after,
input[type="checkbox"]:not(:checked) + label:after {
	content: "";
	position: absolute;
	-webkit-transition: all 0.2s ease;
	-moz-transition: all 0.2s ease;
	-o-transition: all 0.2s ease;
	transition: all 0.2s ease;
}

input[type="checkbox"]:checked + label:after,
input[type="checkbox"]:not(:checked) + label:after {
	left: 3px;
	top: 4px;
	width: 10px;
	height: 5px;
	border-radius: 1px;
	border-left: 4px solid #ff6500;
	border-bottom: 4px solid #ff6500;
	-webkit-transform: rotate(-45deg);
	-moz-transform: rotate(-45deg);
	-o-transform: rotate(-45deg);
	-ms-transform: rotate(-45deg);
	transform: rotate(-45deg);
}
input[type="checkbox"]:not(:checked) + label:after {
	opacity: 0;
}

input[type="checkbox"]:checked + label:after {
	opacity: 1;
}
.form_invalid {
	color: red;
	position: absolute;
	top: 44px;
}
@media (max-width: 500px) {
	.form_container {
		gap: 4px;
		flex-direction: column;
		align-items: center;
	}
	.form {
		width: 90%;
		justify-content: center;
	}
	.textarea {
		width: 255px;
		height: 70px;
	}
	.submit_box {
		flex-direction: column;
	}
	.submit {
		font-weight: 500;
		margin: 0;
		-webkit-text-stroke: 0;
		text-shadow: 1px 1px 1px #ff7e00, -1px -1px 1px #ff7e00,
			-1px 1px 1px #ff7e00, 1px -1px 1px #ff7e00;
	}
	.checkbox_title {
		width: 333px;
		margin-top: 20px;
	}
	.form_invalid_mobile {
		margin-top: 35px;
	}
}

.burger {
	width: 100%;
	height: 100vh;
	background-color: rgba($color: #000000, $alpha: 0.4);
	position: fixed;
	top: 0;
	left: 0;
	display: none;
	align-items: center;
	justify-content: center;
	// transform: scale(0);
}
.burger_active {
	width: 100%;
	height: 100vh;
	background-color: rgba($color: #000000, $alpha: 0.4);
	position: fixed;
	top: 0;
	left: 0;
	z-index: 10;
}

.burger_content {
	padding: 20px;
	border-radius: 0 20px 20px 0;
	width: 200px;
	background-color: white;
	display: flex;
	flex-direction: column;
	gap: 10px;
}
.header_link {
	color: black;
	text-decoration: none;
	font-size: 20px;
	font-weight: bold;
}

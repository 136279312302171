.slider_container {
	max-width: 1500px;
	max-height: 600px;
	margin: 0 auto;
}
.slider_image {
	width: 100%;
	max-height: 600px;
	object-fit: cover;
}

.arrow_img {
	width: 60px;
	margin: 26% auto;
}

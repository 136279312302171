.footer {
	display: flex;
	justify-content: center;
	align-items: center;
	gap: 60px;
	height: 200px;

	border: 2px solid #ff7e00;
}
@media (min-width: 500px) {
	.footer {
		background: linear-gradient(to left, #e77124 30%, white 50%, #e77124 70%);
	}
}
.title,
.subtitle {
	width: 240px;
	color: white;
}
.subtitle {
	display: flex;
	flex-direction: column;
}
.img {
	height: 150px;
}
.subtitle_link {
	margin: 0;
	text-decoration: none;
	color: white;
}
@media (max-width: 500px) {
	.img {
		display: none;
	}
	.footer {
		flex-direction: column;
		background: #e77124;
		gap: 0px;
		padding-bottom: 10px;
	}
	.title {
		width: 350px;
	}
}

.ssm_container {
	width: 320px;
	// z-index: -5;
	display: none;
}
.arrow_img {
	width: 100%;
	height: 100%;
}
.ssm_card {
	width: 216px !important;
	height: 346px;
	// border: 2px solid #f37421;
	// border-radius: 10px;
	margin: 10px;
}
.ssm_img {
	margin: 2% auto;
	height: 17rem;
	width: 13rem;
	border: 1px solid #ff7e00;
}
.ssm_card_title {
	margin: 0;
}

@media (max-width: 500px) {
	.ssm_container {
		display: block;
	}
}

.wcm_container {
	width: auto;
	margin-bottom: 40px;
	display: none;
}
.arrow_img {
	width: 60%;
	height: 30%;
	margin: 35% 0;
}
.wcm_card {
	width: 340px !important;
	height: 236px;
	margin: 10px;
}
.wcm_img {
	margin: 2% auto;
	height: 100%;
	width: 100%;
}

@media (max-width: 500px) {
	.wcm_container {
		display: block;
	}
}

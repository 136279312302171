.container {
	border-bottom: 1px solid rgba(196, 196, 196, 0.5);
}
.header {
	display: flex;
	justify-content: space-between;
	align-items: center;
	margin: 0 auto;
	max-width: 1240px;
	// border-bottom: 1px solid rgba(196, 196, 196, 0.5);
}
.header_burger {
	display: none;
	color: #ff7e00;
	width: 40px;
	height: 40px;
}
.header_logo {
	width: 300px;
	margin: 10px 0;
	/* height: 50px; */
}
.header_link {
	color: black;
	text-decoration: none;
	font-size: 24px;
	font-weight: bold;
}
.header_title {
	width: 138px;
	height: 64px;
}
.header_subtitle {
	margin: 0 0 0 0;
}
@media (max-width: 500px) {
	.header {
		justify-content: space-around;
	}
	.header_logo {
		width: 250px;
	}
	.header_link {
		display: none;
	}
	.header_burger {
		display: block;
	}
	.header_title {
		width: 40px;
		height: 60px;
	}
}

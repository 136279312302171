.slider_container {
	width: 320px;
	// z-index: -5;
	display: none;
}
.client_card {
	width: 80px !important;
	height: 40px;
	border: 2px solid #f37421;
	border-radius: 10px;
	margin: 10px;
}
.client_img {
	width: 50%;
	margin: 7% auto;
	height: 2rem;
	width: 4rem;
}
.arrow_img {
	width: 100%;
	height: 100%;
}

@media (max-width: 500px) {
	.slider_container {
		display: block;
	}
}

.modal {
	width: 100%;
	height: 100vh;
	background-color: rgba($color: #000000, $alpha: 0.4);
	position: fixed;
	top: 0;
	left: 0;
	display: none;
	align-items: center;
	justify-content: center;
	// transform: scale(0);
}
.modal_active {
	width: 100%;
	height: 100vh;
	background-color: rgba($color: #000000, $alpha: 0.4);
	position: fixed;
	top: 0;
	left: 0;
	// transform: scale(1);
	display: flex;
	align-items: center;
	justify-content: center;
	z-index: 100;
}

.modal_content {
	padding: 20px;
	border-radius: 20px;
	background-color: white;
	width: 500px;
}
.form_close {
	color: #ff7e00;
	font-size: 2rem;
	position: relative;
	right: -234px;
}
.form_close:hover {
	color: red;
	cursor: pointer;
}
.form_close:active {
	border-radius: 20px;
	color: white;
	box-shadow: inset 0 0 5px #f37421;
}
.form {
	display: flex;
	flex-direction: column;
	align-items: center;
	gap: 20px;
}
.form_box {
	width: 100%;
}
.form_invalid {
	color: red;
}
.input {
	width: 240px;
	height: 30px;
	font-size: 16px;
}

.checkbox_title {
	width: 300px;
	font-size: 13px;
	margin: 20px 0;
}
.submit_box {
	display: flex;
	flex-direction: column;
	width: 60%;
}
.submit {
	height: 50px;
	border: 1px solid #f37421;
	background-color: rgba($color: #ff7e00, $alpha: 0.6);
	border-radius: 50px;
	color: white;
	font-size: 22px;
	-webkit-text-stroke: 0.05rem rgb(255, 126, 0);
	font-weight: 700;
}
.submit:hover {
	background-color: rgba($color: #ff7e00, $alpha: 1);
	cursor: pointer;
}
.submit:active {
	background-color: white;
	box-shadow: inset 0 0 5px #f37421;
}
@media (max-width: 500px) {
	.modal_content {
		width: 310px;
		padding: 10px;
	}
	.form {
		gap: 5px;
	}
	.form_close {
		right: -138px;
	}
	.checkbox_title {
		width: 190px;
		font-size: 8px;
		line-height: 11px !important;
	}
	.input {
		height: 25px;
		font-size: 14px;
	}
	.form_invalid {
		font-size: 10px;
	}
	.submit {
		font-weight: 500;
		-webkit-text-stroke: 0;
		text-shadow: 1px 1px 1px #ff7e00, -1px -1px 1px #ff7e00,
			-1px 1px 1px #ff7e00, 1px -1px 1px #ff7e00;
	}
}

.services {
	margin: 60px auto;
	display: flex;
	flex-direction: column;
	align-items: center;
	border-bottom: 1px solid rgba(196, 196, 196, 0.5);
}

.services_cards {
	display: flex;
	justify-content: space-between;
	align-items: center;
	flex-wrap: wrap;
	max-width: 1239px;
}
.services_card {
	width: 345px;
	margin-bottom: 77px;
}
.services_card_img {
	width: 100px;
	height: 100px;
	border: 2px solid #ff7e00;
	border-radius: 50%;
	// max-width: 100%;
	// max-height: 100%;
}
.services_card_title {
	font-size: 20px;
	font-weight: 600;
}
.working {
	display: flex;
	flex-direction: column;
	align-items: center;
	border-bottom: 1px solid rgba(196, 196, 196, 0.5);
	padding-bottom: 60px;
}
.working_cards {
	display: flex;
	justify-content: center;
	align-items: center;
	flex-wrap: wrap;
	width: 1419px;
}
.working_card {
	width: 300px;
	height: 200px;
	border: 1px solid black;
	display: flex;
	justify-content: center;
	align-items: end;
	// background-image: url("../Images/Main/working_2.png");
	background-size: cover;
	opacity: 0.9;
}
.working_card_title {
	color: white;
	-webkit-text-stroke: 1px #934817;
	font-size: 20px;
	font-weight: 700;
	text-shadow: 0px 0px 6px black;
	margin: 0 0 5px 0px;
	height: 52px;
}
.working_card_span {
	display: block;
	height: 12px;
}
.working_1 {
	background-image: url("../Images/Main/working_1.jpg");
}
.working_2 {
	background-image: url("../Images/Main/working_2.png");
}
.working_3 {
	background-image: url("../Images/Main/working_3.jpg");
}
.working_4 {
	background-image: url("../Images/Main/working_4.jpg");
}
.working_5 {
	background-image: url("../Images/Main/working_5.jpg");
}
.working_6 {
	background-image: url("../Images/Main/working_6.webp");
}
.working_7 {
	background-image: url("../Images/Main/working_7.jpg");
}
.working_8 {
	background-image: url("../Images/Main/working_8.jpg");
}
.working_9 {
	background-image: url("../Images/Main/working_9.jpg");
}
.working_arrow_back {
	margin: -111px 0px;
	z-index: -5;
}
.principles {
	display: flex;
	flex-direction: column;
	align-items: center;
	background-color: rgba($color: #ff7e00, $alpha: 0.5);
	border: 1px solid #f37421;
	padding-bottom: 20px;
}
.principles_title {
	color: white;
	-webkit-text-stroke: 0.04em #934817;
	text-shadow: 0px 4px 4px #b67a3f;
}
.principles_cards {
	display: flex;
	gap: 80px;
	margin: 20px;
}
.principles_img {
	width: 300px;
	height: 200px;
	border: 1px solid #f37421;
}
.principles_card_title {
	color: white;
	-webkit-text-stroke: 0.8px #934817;
	text-shadow: 0px 4px 4px #b67a3f;
	font-size: 24px;
	font-weight: 700;
	margin: 0;
}

.clients {
	display: flex;
	flex-direction: column;
	align-items: center;
	margin-bottom: 60px;
}
@media (max-width: 500px) {
	.services {
		margin: 30px auto;
	}
	.services_title {
		font-size: 20px;
	}
	.services_cards {
		justify-content: space-around;
	}
	.services_card {
		width: 180px;
		margin-bottom: 20px;
	}
	.services_card_img {
		width: 60px;
		height: 60px;
	}
	.services_card_title {
		font-size: 12px;
	}
	.working_title {
		font-size: 20px;
	}
	.working_cards {
		display: none;
	}
	.principles_cards {
		flex-direction: column;
	}
	.principles_title {
		font-size: 20px;
		font-weight: 600;
	}
	.principles_title,
	.principles_card_title {
		-webkit-text-stroke: 0;
		text-shadow: 1px 1px 1px #934817, -1px -1px 1px #934817,
			-1px 1px 1px #934817, 1px -1px 1px #934817, 0px 4px 4px #b67a3f;
	}

	.working_card_title {
		-webkit-text-stroke: 0;
		text-shadow: 1px 1px 1px #934817, -1px -1px 1px #934817,
			-1px 1px 1px #934817, 1px -1px 1px #934817;
	}
	.principles_cards {
		gap: 40px;
		margin: auto;
	}
	.principles_card_title {
		font-size: 16px;
		font-weight: 400;
	}
	.principles_img {
		width: 250px;
		height: 165px;
	}
	.clients_title {
		font-size: 22px;
	}
}

.container {
	display: block;
	margin: 0 auto 40px;
}
.title {
	margin: 0 0 42px;
}
.contacts {
	display: flex;
	justify-content: center;
	margin: 50px 0;
	gap: 140px;
}
.connection,
.map {
	width: 520px;
	height: 500px;
	display: flex;
	flex-direction: column;
	gap: 20px;
}
.map_style {
	width: 500px;
	height: 500px;
}
.contact {
	display: flex;
	width: 244px;
	align-items: center;
}

.phone_img {
	color: #ff7e00;
	font-size: 90px;
}
.phones {
	display: flex;
	flex-wrap: wrap;
}
.contact_a {
	font-size: 18px;
	text-decoration: none;
	color: black;
	// text-align: left;
}
.email_img {
	color: #ff7e00;
	font-size: 50px;
	margin-right: 10px;
}
.address {
	display: flex;
	width: 550px;
	align-items: center;
}

@media (max-width: 500px) {
	.contacts {
		flex-direction: column;
		align-items: center;
		gap: 0px;
		margin: 50px 0 0 0;
	}
	.connection {
		width: 340px;
		height: 260px;
		gap: 10px;
		align-items: center;
		margin-bottom: 20px;
	}
	.title {
		margin: 0;
		font-size: 20px;
	}
	.phone_img {
		font-size: 50px;
	}
	.contact {
		width: 290px;
	}
	.contact_a {
		font-size: 16px;
		margin: 0;
	}
	.email_img {
		font-size: 37px;
		margin-right: 4px;
	}
	.address {
		width: 280px;
	}
	.map {
		width: 100%;
		height: 400px;
	}
	.map_style {
		width: 100%;
		height: 400px;
	}
}

.container {
	display: block;
	margin: 0 auto;
	// max-width: 1300px;
}
.company,
.resources,
.certificates {
	display: flex;
	flex-direction: column;
	align-items: center;
	margin: 50px 0;
}
.company_subtitle {
	margin: 0;
	font-size: 24px;
	font-weight: 600;
	width: 1090px;
}
.resources_cards {
	display: flex;
	justify-content: space-between;
	flex-wrap: wrap;
	max-width: 1200px;
	gap: 10px;
}

.resources_card {
	width: 300px;
	margin-bottom: 58px;
}
.resources_card img {
	width: 300px;
	height: 150px;
	object-fit: cover;
	border: 2px solid #ff7e00;
}
.resources_card_img1 {
	object-position: 50% 42%;
}

.resources_card_img2 {
	object-position: 50% 2%;
}
.resources_card_title {
	font-size: 22px;
	font-weight: 600;
	margin: 0;
	line-height: 0.9;
}
.resources_card_subtitle {
	font-size: 16px;
	font-weight: 500;
	margin: 0;
}
.certificates_subtitle {
	width: 1150px;
	// text-align: left;
	margin: 0;
	font-size: 20px;
}
.certificates_card_img {
	height: 420px;
	border: 2px solid #ff7e00;
}
.certificates_cards {
	display: flex;
	justify-content: space-between;
	// flex-wrap: wrap;
	width: 1200px;
	margin-top: 42px;
}
.certificates_card {
	width: 300px;
}
.certificates_card_title {
	margin: 0;
	font-size: 20px;
}
.works {
	display: flex;
	flex-direction: column;
	max-width: 1300px;
	margin: 0 auto;
}
.grey {
	border-top: 1px solid rgba($color: #c4c4c4, $alpha: 0.5);
}

.works_cards {
	display: flex;
	justify-content: space-between;
	flex-wrap: wrap;
	gap: 60px;
	margin: 30px 0 50px 0;
}
.works_img {
	width: 620px;
	height: 400px;
}
.works_imgBig {
	width: 100%;
}
@media (max-width: 500px) {
	.company {
		margin: 20px 0;
	}
	.company_subtitle {
		font-size: 16px;
		font-weight: 400;
		width: 300px;
	}
	.resources {
		margin: 0;
	}
	.resources_title {
		margin: 0 0 10px 0;
		font-size: 20px;
	}
	.resources_cards {
		justify-content: center;
		gap: 20px;
	}
	.resources_card img {
		width: 135px;
		height: 80px;
	}
	.resources_card {
		margin-bottom: 0px;
		height: 110px;
		width: 140px;
	}
	.resources_card_title {
		font-size: 12px;
	}
	.certificates {
		margin: 5px 0;
	}
	.certificates_title {
		font-size: 20px;
	}
	.certificates_subtitle {
		width: 310px;
		font-size: 13px;
	}
	.certificates_cards {
		display: none;
	}
	.works_title {
		font-size: 20px;
	}
	.works_cards {
		display: none;
	}
}

.container {
	display: flex;
	flex-direction: column;
	width: 1240px;
	margin: 0 auto 50px;
}
.main {
	background-image: url(../Images/Services/services-main.jpg);
	width: 100%;
	height: 460px;
	background-size: 100% 100%;
	display: flex;
	align-items: center;
	justify-content: center;
}
.title {
	width: 1030px;
	color: white;
	-webkit-text-stroke: 0.08rem #ba530e;
	text-shadow: 0px 2px 6px #934817;
}
.cards {
	display: flex;
	flex-wrap: wrap;
	gap: 60px;
	justify-content: center;
}
.img {
	width: 370px;
	height: 280px;
	border: 2px solid #ff7e00;
}
.card_txt {
	width: 370px;
	font-size: 20px;
	font-weight: 600;
	margin: 0;
}
@media (max-width: 500px) {
	.container {
		max-width: 400px;
	}
	.main {
		height: 200px;
	}
	.title {
		width: 341px;
		font-size: 18px;
		font-weight: 500;
		-webkit-text-stroke: 0;
		text-shadow: 1px 1px 1px #ba530e, -1px -1px 1px #ba530e,
			-1px 1px 1px #ba530e, 1px -1px 1px #ba530e, 0px 2px 6px #934817;
	}
	.img {
		width: 180px;
		height: 150px;
	}
	.cards {
		gap: 20px;
	}
	.card_txt {
		width: 180px;
		font-size: 12px;
	}
}

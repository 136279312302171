.slider_container {
	width: 1120px;
	// z-index: -5;
}
.client_card {
	width: 180px !important;
	height: 70px;
	border: 2px solid #f37421;
	border-radius: 10px;
	margin: 10px;
}
.client_img {
	width: 80%;
	margin: 7% auto;
	height: 3rem;
	width: 8rem;
}

@media (max-width: 500px) {
	.slider_container {
		display: none;
	}
}
